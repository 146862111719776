@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import "./functions";
@import "./variables";

@import "~bootstrap/scss/bootstrap.scss";

@import "./styles/navbar";

@import "./styles/home";
@import "./styles/loading-screen";
@import "./styles/cookies-bar";

#btnToTop {
    right: 30px;
    bottom: 30px;
}
