#hero {
    width: 100%;
    margin-bottom: 50px;

    .hero-banner {
        width: 100%;
        height: auto;
    }

    .btn-play {
        top: 50%;
    }
}

#about {
    margin-top: 50px;
    margin-bottom: 50px;
    text-align: center;

    .title {
        margin-bottom: 1.5rem;

        span {
            background: $black;
            color: $white;
            font-size: 20px;
            padding: .2rem 1.5rem;
        }
    }

    .subtitle {
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 5px;
    }

    .content {
        font-size: 18px;
        line-height: 25px;
        font-weight: 500;
    }
}

#solutions {
    margin-top: 50px;

    img {
        width: 60%;
        display: block;
    }

    .solution-title {
        margin-top: -250px !important;
        margin-left: 30px;
        margin-right: 0;

        @media only screen and (min-width: 1786px) {
            margin-top: -275px !important;
        }

        .solution-title-left {
            background-color: rgba($white, .7);
            justify-content: center;
            align-items: center;
            display: flex;

            .title {
                font-weight: 700;
                font-size: 38px;
                color: $black;
            }
        }

        .solution-title-right {
            background-color: rgba($black, .7);
            padding: .5rem 1rem;

            .subtitle {
                font-weight: 600;
                font-size: 18px;
                line-height: 20px;
                color: $white;
            }

            .content {
                font-weight: 400;
                font-size: 18px;
                line-height: 20px;
                color: $white;
                margin: 0;
            }
        }
    }
}

#solutions-content {
    background-image: url('../../img/bg-slope-white.png'), url('../../img/bg-pattern-01.jpg');
    background-repeat: no-repeat, repeat;
    background-size: contain, 300px;
    background-position: top center, unset;
    width: 100%;
    min-height: 250px;
    margin-top: -125px;
    padding-top: 198px;

    @media only screen and (min-width: 1786px) {
        margin-top: -150px !important;
        padding-top: 234px;
    }

    @media only screen and (max-width: 576px) {
        width: 100vw;
    }

    .card {
        background-color: $secondary;
        border-color: $white;
        border-width: 1.5px;
        border-radius: 1rem;
        cursor: pointer;

        .info {
            position: absolute;
            color: $white;
            right: 10px;
            top: 10px;
            font-size: 22px;

            @media screen and (max-width: 960px) {
                right: 10px;
                top: 10px;
                font-size: 18px;
            }
        }

        img {
            height: 58px;
            margin-bottom: 5px;

            @media screen and (max-width: 320px) {
                height: 54px !important;
            }
        }

        .title {
            font-weight: 600;
            color: $white;
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            display: flex;
            min-height: 48px;
            align-items: center;
            justify-content: center;
            margin-bottom: 0;

            @media screen and (max-width: 320px) {
                font-size: 16px !important;
            }
        }

        .content {
            color: $white;
            font-size: 14.5px;
            margin-bottom: 0;
            text-align: justify;

            &.hyphens {
                -webkit-hyphens: auto;
                -moz-hyphens: auto;
                -ms-hyphens: auto;
                hyphens: auto;
            }

            &.min-height-214 {
                min-height: 214px;
                @media only screen and (max-width: 992px) {
                    min-height: 0;
                }
            }

            &.min-height-192 {
                min-height: 192px;
                @media only screen and (max-width: 992px) {
                    min-height: 0;
                }
            }

            &.min-height-152 {
                min-height: 152px;
                @media only screen and (max-width: 992px) {
                    min-height: 0;
                }
            }
        }
    }
}

#industries {
    background-color: #e6e6e6;
    padding-top: 50px;
    padding-bottom: 50px;

    .industries-title {
        margin-bottom: 10vw;

        @media only screen and (min-width: 1786px) {
            margin-bottom: 8.5vw;
        }

        img {
            width: 100%;
            height: auto;
            display: block;
            margin-bottom: -16vw;

            @media only screen and (min-width: 1786px) {
                margin-bottom: -11.5vw;
            }
        }

        .industries-title-left {
            background-color: rgba($black, .7);
            padding: .5rem 1rem;

            .subtitle {
                font-weight: 600;
                font-size: 20px;
                line-height: 22px;
                color: $white;
            }

            .content {
                font-weight: 400;
                font-size: 20px;
                line-height: 22px;
                color: $white;
                margin: 0;
            }
        }

        .industries-title-right {
            background-color: rgba($white, .7);
            justify-content: center;
            align-items: center;
            display: flex;

            .title {
                font-weight: 700;
                font-size: 38px;
                color: $black;
            }
        }
    }

    .industries-content-tabs {

        .opacity-3 {
            opacity: .3;

            &:hover {
                opacity: 1;
            }
        }

        .active {
            background-color: $secondary !important;
            color: $white;
            cursor: pointer;
            border-color: $secondary;
            display: inline-block;

            .icon-black {
                display: none !important;
            }

            .icon-white {
                display: block !important;
            }

            &::after {
                content: '';
                display: block;
                width: 0;
                height: 0;
                border-top: solid 20px $secondary;
                border-right: solid 20px transparent;
                border-left: solid 20px transparent;
                position: relative;
                margin-bottom: -20px;
                margin-left: 19vw;
                margin-right: auto;
                margin-top: 76px;
                position: absolute;

                @media only screen and (min-width: 1786px) {
                    margin-left: 15vw;
                }
            }
        }

        .content-tabs-item {
            border: 1.8px solid $black;
            padding: 5px;
            border-radius: 8px;
            min-height: 0px;
            display: flex;
            background-color: $white;
            justify-content: space-between;

            .icon-black {
                display: block;
            }

            .icon-white {
                display: none;
            }

            &:hover {
                background-color: $secondary !important;
                color: $white;
                cursor: pointer;
                border-color: $secondary;

                .icon-black {
                    display: none;
                }

                .icon-white {
                    display: block;
                }
            }

            .icons {
                width: 18%;
                display: flex;
                padding-left: 15px;
                padding-right: 15px;
                justify-content: center;
                align-items: center;

                img {
                    height: 72px;
                }
            }

            .tab-title {
                display: flex;
                width: 76%;
                font-weight: 700;
                align-items: center;
                justify-content: center;
                text-align: center;
            }

            .info {
                font-size: 22px;
                margin-left: auto;
                display: inline-block;
            }
        }

        .content-tabs-content {
            display: none;
            margin-bottom: 1.5rem;

            h4 {
                font-size: 1.3rem !important;
            }

            h5 {
                font-size: 1rem !important;
            }

            img {
                width: 100%;
            }

            .flag-globe {
                width: 50%;
            }
        }
    }
}

#contacts {
    padding-top: 52px;
    padding-bottom: 50px;
    background: linear-gradient(transparent, rgba($black, .85)),
        url('../../img/bg-pattern-01.jpg') repeat;
    background-size: auto, 300px;

    .title {
        display: block;
        margin-bottom: 38px;
        text-align: center;

        span {
            background-color: $black;
            color: $white;
            padding: .5rem 2.5rem;
        }
    }

    .contact_person {
        padding: 1rem;
        background-color: rgba($secondary, .8);

        .info-text {
            color: $white;
            font-size: 18px;

            a {
                text-decoration: none;
                color: $white;

                &:hover {
                    color: $gray-100;
                }
            }
        }
    }
}

.navbar-light .navbar-toggler {
    border: 0;

    &:focus {
        box-shadow: none;
    }
}

@media only screen and (max-width: 992px) {
    .navbar-collapse {
        margin-top: 22px !important;
    }

    .navbar-light .navbar-nav .nav-link {
        padding: 8px;
    }

    #hero {
        margin-bottom: 32px;
    }

    #about {
        margin-top: 24px;
        margin-bottom: 18px;
        text-align: center;

        .title {
            margin-bottom: 1rem;
        }

        .subtitle {
            font-size: 16px;
            line-height: 18px;
            margin-bottom: 12px;
        }

        .content {
            font-size: 15px;
            line-height: 17px;
            text-align: justify;
        }
    }

    #solutions {
        margin-top:0;

        img {
            width: 100vw;
            margin-left: -15.8vw;
        }

        .solution-title {
            margin-top: -34vw !important;
            margin-left: 0;
            margin-right: 0;

            .solution-title-left {

                .title {
                    font-size: 28px;
                }
            }

            .solution-title-right {

                .subtitle {
                    font-size: 14px;
                    line-height: 20px;
                    margin-bottom: .5rem;
                }

                .content {
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }
    }

    #solutions-content {
        background-image: url('../../img/bg-slope-white-m.png'), url('../../img/bg-pattern-01.jpg');
        background-size: contain, 300px;
        margin-top: 32px;
        padding-top: 0;

        .card {

            img {
                height: 64px;
                margin-bottom: 5px;
                display: inline-block;
            }

            .title {
                font-size: 18px;
                line-height: 20px;
                display: flex;
                align-items: center;
            }

            .content {
                font-size: 14px;
                min-height: 80px;
                padding-top: 12px;
            }
        }
    }

    #industries {
        background-color: $white;
        padding-top: 32px;
        padding-bottom: 32px;

        .industries-title {

            img {
                width: 100vw;
                margin-bottom: -36vw;
                margin-left: -15.8vw;

                @media only screen and (min-width: 1786px) {
                    margin-bottom: -11.5vw;
                }
            }

            .industries-title-left {
                .subtitle {
                    font-size: 14px;
                    line-height: 20px;
                }

                .content {
                    font-size: 14px;
                    line-height: 20px;
                    color: $white;
                    margin: 0;
                }
            }

            .industries-title-right {
                padding-top: 1.7rem;
                padding-bottom: 1.7rem;

                .title {
                    font-weight: 600;
                    font-size: 24px;
                    margin: 0;
                }
            }
        }

        .industries-content-tabs {

            .active {

                &::after {
                    margin-left: 35.5vw;
                    margin-top: 48px;
                }
            }

            .content-tabs-item {
                .icons {
                    width: 24%;
                    padding-left: 5px;
                    padding-right: 5px;

                    img {
                        height: 48px;
                    }
                }

                .tab-title {
                    width: 68%;
                    font-size: 16px;

                    @media (max-width: 320px) {
                        font-size: 14px;
                    }
                }

                .info {
                    font-size: 22px;
                    margin-left: auto;
                    display: inline-block;

                    @media (max-width: 320px) {
                        font-size: 16px;
                    }
                }
            }

            .content-tabs-content {
                display: none;
                margin-bottom: 1.5rem;

                h4 {
                    font-size: 1.3rem !important;
                }

                h5 {
                    font-size: 1rem !important;
                }

                img {
                    width: 100%;
                }

                .flag-globe {
                    width: 50%;
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    #solutions {

        img {
            margin-left: -6vw;
        }
    }

    #industries {
        .industries-title {
            img {
                margin-left: -6.9vw;
            }
        }
    }
}

@media only screen and (max-width: 576px) {
    #solutions {

        img {
            margin-left: -3vw;

            @media only screen and (max-width: 376px) {
                margin-left: -3.09vw;
            }
        }
    }

    #industries {
        .industries-title {
            img {
                margin-left: -5.9vw;

                @media only screen and (max-width: 376px) {
                    margin-left: -6.5vw;
                }
            }
        }
    }
}

#label-bar-chart {
    width: 110px;
    padding-left: 7px;
    text-align:left;

    .label {
        width: 28px;
        font-size: 11px;
        text-align: center;
        margin-right: 0.6em;
        display: inline-block;
    }

    @media only screen and (max-width: 1786px) {
        width: 95px;

        .label {
            width: 28px;
            font-size: 11px;
            text-align: center;
            margin-right: 0.6em;
            display: inline-block;
        }
    }

    @media only screen and (max-width: 1400px) {
        width: 95px;

        .label {
            width: 23px;
            font-size: 9px;
            margin-right: 0.65em;
        }
    }

    @media only screen and (max-width: 1200px) {
        width: 95px;
    }

    @media only screen and (max-width: 992px) {
        width: 368px;
        padding-left: 0;

        .label {
            width: 105px;
            font-size: 16px;
            margin-right: 27px;
        }
    }

    @media only screen and (max-width: 769px) {
        width: 267px;

        .label {
            width: 76px;
            font-size: 14px;
            margin-right: 20px;
        }
    }

    @media only screen and (max-width: 576px) {
        width: 267px;

        .label {
            width: 56px;
            font-size: 13px;
            margin-right: 13px;
        }
    }

    @media only screen and (max-width: 420px) {
        width: 195px;
        padding-left: 5px;

        .label {
            width: 56px;
            font-size: 13px;
            margin-right: 9px;
        }
    }

    @media only screen and (max-width: 376px) {
        width: 172px;
        padding-left: 0;

        .label {
            width: 50px;
            font-size: 13px;
            margin-right: 11px;
        }
    }
}
