.navbar-light {

    .navbar-nav {

        .nav-link {
            margin-right: .2rem;
            margin-left: .2rem;

            &.active,
            &:hover {
                background-color: $black;
                color: $white !important;
            }

            &:focus {
                color: $black;
            }
        }

        .nav-item-divider {
            border-right: 1px solid $black;
            margin-left: .5rem;
            margin-right: .5rem;
        }
    }
}